.button {
    background-color: rgba(hsl(176, 100%, 50%, 64%), green, blue, alpha);

    align-items: center;

    display: flex;
    justify-content: center;
    border-radius: 100px;
    opacity: 0.85;
    align-self: flex-end;
    margin-bottom: 3em;
    margin-right: 3em;
    padding-left: 2em;
    padding-right: 2em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
}


.button>a {
    text-decoration: none;
    color: white;
    font-size: 24px;
    text-align: center;
    font-family: 'Montserrat';
    font-weight: 500;
    display: flex;
    font-weight: 700;

    position: relative;
}

.button_black { 
    background-color: rgb(31,54,62);
    box-shadow: 0px 6.74074px 6.74074px rgba(0, 0, 0, 0.25);
    border-radius: 26.963px;
}


.button_black>a {
    color: white
}