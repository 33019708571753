.home_itemsContainer{
    scroll-snap-type: y mandatory;
    overflow: auto;

    height: 100vh;
    scroll-behavior: smooth;
    scroll-margin-block-end: initial;
    position: relative;
   align-items: center;
}



.section_padding {
    padding: 4rem 6rem;
}

.section_margin {
    padding: 4rem 6rem;
}

.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-2-16 14:21:45
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-up-center
 * ----------------------------------------
 */
 @-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
   
@media screen and (max-width: 700px) {
    .section_padding {
        padding: 4rem
    }

    .section_margin {
        margin: 4rem;
    }
}

@media screen and (max-width: 550px) {
    .section_padding {
        padding: 4rem 2rem;
    }

    .section_margin {
        margin: 4rem 2rem;
    }
}
