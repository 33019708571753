
.content-container {
    padding-top: 65vh;
}


.content-container .benefits-heading {

    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 59px;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    color: #FFFFFF;
}

.content-container .benefit {
    
    background-color: #00000033;
    display: flex;
    align-items: center;
    margin: 1em;
    padding: 0.5em;
    border-radius: 1em;
}
.content-container .benefit p {
    
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.3em;
    text-transform: uppercase;

    color: #FFFFFF;

}

.benefit img {
    width: 100%;
}

.item {
    width: 100vw;
    height: 100vh;
    scroll-snap-type: y mandatory;
    scroll-behavior: smooth;
    scroll-snap-align: center;

    justify-content: center;

    display: flex;
    justify-content: center;
    box-sizing: border-box;
    scroll-behavior: smooth;
    overflow-y: scroll;
    scroll-behavior: smooth;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;    
}

.item_container{
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;    
}

.down_arrow img {
    background-color: #1f363e;

}

.down_arrow img {

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 5px 5px;



    position: absolute;
    left: 50.0%;
    top: 90.34%;

   fill: #FFFFFF;


    height: 50.75px;
    width: 50.75px;

    left: nullpx;
    top: nullpx;
    border-radius: 0px;
    animation: wiggle infinite 1.5s;
    overflow-x: hidden;

}

