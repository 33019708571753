.nav-item {
    color: #F2F0EF;

    font-family: 'Montserrat';
    font-weight: 500;
    font-style: normal;
    font-size: 24px;
    
    line-height: 29px;
    letter-spacing: 0.04em;
    margin-left: 1em;
}
@media (max-width: 767px) {
    .navbar {
        background-color:rgba( 0, 0, 0, 0.66 );
    }
}
