.second_item_container {
    background-size: cover;
    scroll-snap-type: y mandatory;
    scroll-behavior: smooth;
    scroll-snap-align: center;
}

.second_item_container .row {
    margin-top: 1em;
}

.button-no-margin .button {
    margin:0 !important;
}

.second_item_container .card {
    padding: 1em;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 1em;
    background-color: #66919681;

    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    
    color:white;
}
.card h3 {
    font-size: 30px;
    margin-bottom: 0.7em;
}
.card h3 img {
    height: 1em;
    margin-right: 0.5em;
}
.section-title {
    margin-top: 3em;
    margin-bottom: 1em;
}
.section-title h2 {
    
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    /* identical to box height */

    letter-spacing: 0.3em;
    text-transform: uppercase;

    color: #FFFFFF;
}
